<template>
  <Header />
  <div id="Contact">

  <div class="form-area lg:w-2/5 md:w-1/2 w-2/3 mx-auto">
    <form class="bg-white p-10 rounded-lg shadow-lg min-w-full">

      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">Contact</h2>

    <div>
      <label class="text-left text-gray-800 font-semibold block my-3 text-md" for="username">Name</label>
      <input class="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none" v-model="name" type="text" name="username" id="username" placeholder="My name" />
    </div>
    <div>
      <label class="text-left text-gray-800 font-semibold block my-3 text-md" for="email">Email</label>
      <input class="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none" v-model="email" type="email" name="email" id="email" placeholder="@email" />
    </div>
    <div>
      <label class="text-left text-gray-800 font-semibold block my-3 text-md" for="subject">Subject</label>
      <input class="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none" v-model="subject" type="text" placeholder="What is your topic?" />
    </div>
    <div>
      <label class="text-left text-gray-800 font-semibold block my-3 text-md" for="confirm">Message</label>
      <textarea class="resize-none rounded-md w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"  v-model="message" placeholder="Type your message..."></textarea>
    </div>

      <button type="submit" class="w-full mt-6 bg-blue-400 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans"
        @click.prevent="sendEmail()"
        v-if="!processing">
        Submit
      </button>

    <div class="processing" v-else-if="processing">
      <img src="@/assets/gfx/loading.gif" alt="Please wait..." />
    </div>

    </form>
  </div>

  </div>
  <Footer />
</template>
<script>
import axios from 'axios'
import Header from '@/components/layouts/sections/Header.vue'
import Footer from '@/components/layouts/sections/Footer.vue'
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default{
  props:['isMobile'],
  components:{
    Header,
    Footer
  },
data(){
  return{
    name: '',
    email: '',
    subject: '',
    message: '',
    feedback: '',
    status: '',
    processing: false
  }
},
methods:{
  returnToLastRoute(){
    this.$router.back()
  },
  sendEmail(){
    this.processing = true
    let payload ={
      name:this.name,
      email:this.email,
      subject:this.subject,
      message:this.message
    }
    axios.post('https://api.skylineexecutive.com/contact', payload)
    .then((res)=>{
      this.name = ''
      this.email = ''
      this.subject= ''
      this.message = ''
      this.feedback = res.data.feedback
      this.status = res.data.status
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Could not send email'
      this.processing = false
      console.log(err)
    })
  }
}
}
</script>
<style scoped>
#Contact{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  min-height: 80vh;
  font-size: 1rem;
  width: 100%;
  margin: 0;
  background: #fff;
}
.form-area{
  padding-top: 5%;
  min-width: 20rem;
}
</style>
